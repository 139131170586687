<template>
  <div class="st-container" :class="{'up-open': type==='tabs' && enableSearchAgentState}">
    <v-select
      name="v-select-destination"
      v-model="selected"
      :options="destinationOptions"
      :dir="isRTLPage ? 'rtl' : 'ltr'"
      :clearable="false"
      :placeholder="$t('search-tab.choose-destination')"
      @option:selecting="selecting"
      @search:focus="deselecting"
      class="style-chooser"
    >
    <template #selected-option="{ label }">
      <div style="display: flex; align-items: baseline;">
        <span style="margin-left:10px; margin-right:10px;">
          <i class="fa fa-plane" aria-hidden="true"></i>
        </span>
        {{ label }}
      </div>
    </template>
    <template v-slot:option="{ label }">
      <span style="margin-left:10px; margin-right:10px; font-size:1.5rem;">
          <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
      </span>
      {{ label }}
    </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import TemplateSearchSelect from './TemplateSearchSelect';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'SelectTheme0',
  mixins: [TemplateSearchSelect],
  components: {
    vSelect,
  },
};
</script>
<style scoped>
.st-container {
  overflow: visible;
}
</style>
<style>
input[type=search]{
  margin: 0;
  padding: 0;
  border: 0;
}
.st-container .vs--searchable .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: auto;
}
.vs__selected{
  width: max-content;
}

.style-chooser .vs__dropdown-menu li {
  height: 45px;
  line-height: 35px;
  transition: 0.2s;
}

.vs__dropdown-option {
    color: #333 !important;
}
.st-container.up-open .vs__dropdown-menu {
  top: auto;
  bottom: 50px;
}

</style>
