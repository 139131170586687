import { mapGetters } from 'vuex';

export default {
  name: 'Select',
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      currentPage: 'GET_CURRENT_PAGE',
      isEmalonMode: 'GET_IS_EMALON_MODE',
      isRTLPage: 'GET_IS_RLT_PAGE',
    }),
    enableSearchAgentState() {
      const { isOdysseySite, currentPage, device } = this;
      return ((isOdysseySite && currentPage !== 'search-result')) && device === 'desktop';
    },
    stateSelected: {
      get() {
        const ts = this.$store.getters.GET_SEARCH_CONTENT.dest;
        return ts || this.destinationOptions[0];
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['dest', value]);
      },
    },
  },
  watch: {
    selected() {
      this.$emit('changeDestination', this.selected);
      this.stateSelected = this.selected;
    },
    stateSelected() {
      this.selected = this.stateSelected;
    },
  },
  data() {
    return {
      selected: null,
      destinationOptions: [],
      query: this.$route.query,
    };
  },
  mounted() {
    this.makeSelectOption();
  },
  methods: {
    makeSelectOption() {
      this.destinationOptions = [];
      this.list.forEach((item, inx) => {
        this.destinationOptions.push({
          id: inx,
          value: item.code,
          label: item.name,
        });
      });
      const qs = (this.query.city) ? this.query.city : '';
      this.selected = (qs) ? this.destinationOptions.find((elm) => elm.value === qs) : this.destinationOptions[0];
    },
    selecting() {
      this.$emit('optionSelect', true);
    },
    deselecting() {
      this.$emit('optionSelect', false);
    },
  },
};
